import React, { useEffect } from 'react';
import { useAppContext } from '../../store/AppContext';

const AppPopover = () => {
  const [{
    popover: {
      isOpen, title, isSuccess, dontHide,
    },
  }, appDispatch] = useAppContext();

  const hide = () => {
    appDispatch({
      type: 'SET_POPOVER',
      payload: {
        isOpen: false, title: '', isSuccess, dontHide: false,
      },
    });
  };

  useEffect(() => {
    if (isOpen && !dontHide) {
      setTimeout(hide, 3000);
    }
  }, [isOpen, dontHide]);

  return (
    isOpen
      ? (
        <div
          className={isSuccess
            ? 'popover-container popover-success' : 'popover-container popover-error'}
        >
          <span>{title}</span>
          <button
            type="button"
            onClick={hide}
          >
            <span
              className={isSuccess ? 'close-button close-button-success' : 'close-button close-button-error'}
            >
              X
            </span>
          </button>
        </div>
      ) : null
  );
};

export default AppPopover;
