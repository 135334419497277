/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
import React, {
  useEffect, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from 'react-query';
import { LoaderComponent, ButtonComponent } from '@zolteam/axenergie-ui-library';

// Components
import ModalComponent from '../atoms/ModalComponent';
import ContactModal from './ContactModal';

// Hooks
import { useAppContext } from '../../store/AppContext';

// Service
import AgencyContractService from '../../services/AgencyContractService';

// Utils
import urlUtils from '../../utils/urlUtils';

// Constants
import strings from '../../constants/strings';
import utils from '../../utils/utils';
import colors from '../../constants/colors';

const QuoteTab = ({ agencyContractId, signed }) => {
  const [{ currentAgency, clientAgencies }, appDispatch] = useAppContext();

  const [quoteValidateModal, setQuoteValidateModal] = useState({
    open: false,
    quote: null,
  });
  const [quoteRefuseModal, setQuoteRefuseModal] = useState({
    open: false,
    quote: null,
  });

  const [contactModalOpen, setContactModalOpen] = useState(false);

  const getMyAgencyContractQuotations = useQuery(['get-my-agency-contracts-quotations', agencyContractId], () => {
    if (agencyContractId) {
      return AgencyContractService.getMyAgencyContractQuotations({ agencyContractId });
    }
    return null;
  });

  const acceptQuotation = useMutation('accept-quotation', (quote) => {
    if (quote) {
      return AgencyContractService.acceptAgencyContractQuotation(quote.id);
    }
    return null;
  });

  const refuseQuotation = useMutation('refuseQuotation', (quote) => {
    if (quote) {
      return AgencyContractService.refuseAgencyContractQuotation(quote.id);
    }
    return null;
  });

  // Callback when signature is done
  const redirectToPayment = () => {
    const url = `https://${process.env.REACT_APP_SITE_DOMAIN}${urlUtils.getAgenceUrl({
      id: currentAgency,
      title: JSON.parse(clientAgencies)[0].name,
    })}?payment=open&quoteSigned=true`;
    window.open(url, '_blank').focus();
  };

  const getStatusToDisplay = (quote) => {
    if (!!quote?.envelopeFileId || quote?.isAccepted) {
      return <p className="m-0 green-text medium-weight mv-2 mh-4">{strings.quoteSigned}</p>;
    }
    if (quote?.isRefusedLocally) {
      return <p className="m-0 primary-red-text medium-weight mv-2 mh-4">{strings.refusedQuotation}</p>;
    }
    if (quote?.isAccepted === null) {
      return <p className="m-0 small-text mv-2 mh-4 ws-pre-wrap text-align-center">{strings.quoteUnknowStatus}</p>;
    }
    return null;
  };

  const canShowActionButton = (quote) => quote?.isAccepted === false
  && !quote?.isAcceptedLocally && !quote?.isRefusedLocally;

  useEffect(() => {
    if (signed === 'true') {
      redirectToPayment();
    }
  }, [signed]);

  const initializeQuotationSignature = useMutation('initialize-quotation-signature', async (quoteId) => {
    if (quoteId) {
      return AgencyContractService.initializeAgencyContractQuotationSignature(quoteId, agencyContractId);
    }
    return null;
  });

  const getAgencyContractQuotationFile = useMutation('get-agency-contract-quotation',
    (fileId) => AgencyContractService.getAgencyContractQuotationFile(fileId));

  const getAgencyContractQuotationSignedFile = useMutation('get-agency-contract-quotation-signed',
    (contractId) => AgencyContractService.getAgencyContractQuotationSignedFile(contractId));

  const handleQuoteValidateClick = (quote) => {
    setQuoteValidateModal({
      open: true,
      quote,
    });
  };

  const handleQuoteRefuseClick = (quote) => {
    setQuoteRefuseModal({
      open: true,
      quote,
    });
  };

  // useEffect(() => {
  //   if (getMyAgencyContractQuotations.isSuccess) {
  //     setQuoteList(getMyAgencyContractQuotations?.data?.data?.agencyContractQuotations);
  //   }
  //   if (getMyAgencyContractQuotations.isError) {
  //     setQuoteList([]);
  //     appDispatch({
  //       type: 'SET_POPOVER',
  //       payload: { isOpen: true, title: strings.errors.getQuotes, isSuccess: false },
  //     });
  //   }
  // }, [getMyAgencyContractQuotations.isSuccess, getMyAgencyContractQuotations.isError]);

  useEffect(() => {
    if (acceptQuotation.isSuccess) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true, title: strings.success.validateQuote, isSuccess: true, dontHide: true,
        },
      });
    }
    if (acceptQuotation.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.validateQuote, isSuccess: false },
      });
    }
    setQuoteValidateModal({
      open: false,
      quote: null,
    });
  }, [acceptQuotation.isSuccess, acceptQuotation.isError]);

  useEffect(() => {
    if (refuseQuotation.isSuccess) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: {
          isOpen: true, title: strings.success.refuseQuote, isSuccess: true, dontHide: true,
        },
      });
      getMyAgencyContractQuotations.refetch();
    }
    if (refuseQuotation.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.refuseQuote, isSuccess: false },
      });
    }
    setQuoteRefuseModal({
      open: false,
      quote: null,
    });
  }, [refuseQuotation.isSuccess, refuseQuotation.isError]);

  useEffect(() => {
    if (initializeQuotationSignature.isSuccess) {
      window.location.replace(initializeQuotationSignature?.data?.data?.signatureUrl);
    }
    if (initializeQuotationSignature.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.validateQuote, isSuccess: false },
      });
    }
  }, [initializeQuotationSignature.isSuccess, initializeQuotationSignature.isError, window.location]);

  useEffect(() => {
    if (getAgencyContractQuotationFile.isSuccess) {
      const file = getAgencyContractQuotationFile?.data;

      if (file) {
        utils.downloadBlob(file?.data, file?.headers['x-file-sanitized-name']);
      }
    }
    if (getAgencyContractQuotationFile.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.agencyDocumentNotFound, isSuccess: false },
      });
    }
  }, [getAgencyContractQuotationFile.isSuccess, getAgencyContractQuotationFile.isError]);

  useEffect(() => {
    if (getAgencyContractQuotationSignedFile.isSuccess) {
      const signedFile = getAgencyContractQuotationSignedFile?.data;

      if (signedFile) {
        utils.downloadBlob(signedFile?.data, signedFile?.headers['x-file-sanitized-name']);
      }
    }
    if (getAgencyContractQuotationSignedFile.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.agencyDocumentNotFound, isSuccess: false },
      });
    }
  }, [getAgencyContractQuotationSignedFile.isSuccess, getAgencyContractQuotationSignedFile.isError]);

  const handleDocumentClick = (quote) => {
    if (quote.envelopeFileId === null) {
      getAgencyContractQuotationFile.mutate(quote.id);
    } else {
      getAgencyContractQuotationSignedFile.mutate(quote.id);
    }
  };

  const handleContactOpen = (e) => {
    e.stopPropagation();
    setContactModalOpen(true);
  };
  const sendDatalayer = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'download',
      category: 'devis',
    });
  };

  const isQuoteUnsigned = (quote) => !!(
    !quote.isAccepted
    && !quote.isAcceptedLocally
    && quote.hasFile);

  const quoteList = useMemo(() => getMyAgencyContractQuotations?.data?.data?.agencyContractQuotations
    ?.filter(({ hasFile }) => !!hasFile),
  [getMyAgencyContractQuotations]);

  return (
    <>
      <div className="d-flex f-column white-background radius-5 pv-3 ph-5">
        {quoteList === null && <LoaderComponent size={20} color={colors.grey800} borderWidth={3} />}
        {quoteList !== null && quoteList?.length === 0 && <div className="pv-4">{strings.noQuoteAvailable}</div>}
        {quoteList !== null && quoteList?.length > 0
        && (
          <ul className="p-0" style={{ listStyle: 'none', flexGrow: 1 }}>
            {quoteList.map((quote) => (
              <li key={`todo-${quote.number}`} className="list pv-4">
                <div className="d-flex justify-between align-center f-wrap">
                  <div className="d-flex f-column">
                    <div className="medium-weight mb-2 d-flex f-row f-wrap">
                      <span className="mr-4">
                        {strings.quote(quote.number)}
                      </span>
                      <span className="primary-red-text">{`${quote.amountIncludingTax / 100}€ TTC`}</span>
                    </div>
                  </div>
                  <div
                    className="d-flex justify-end align-center f-wrap"
                    style={{ flexGrow: '0.4' }}
                  >
                    {canShowActionButton(quote)
                      ? (
                        <div className="d-flex mr-4" style={{ gap: '20px' }}>
                          <ButtonComponent
                            onClick={() => handleQuoteValidateClick(quote)}
                            theme="primary"
                          >
                            {strings.quoteValidate}
                          </ButtonComponent>
                          <ButtonComponent
                            onClick={() => handleQuoteRefuseClick(quote)}
                            theme="outline"
                          >
                            {strings.quoteRefuse}
                          </ButtonComponent>
                        </div>
                      ) : null}
                    {getStatusToDisplay(quote)}
                    <div className="d-flex align-center">
                      {quote.hasFile
                          && (
                          <a
                            role="button"
                            tabIndex={0}
                            onKeyDown={null}
                            onClick={() => {
                              sendDatalayer();
                              handleDocumentClick(quote);
                            }}
                            className="m-0 primary-red-text bold mr-4 pointer mv-2"
                          >
                            {strings.downloadQuoteInvoice('quote')}
                          </a>
                          ) }
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
        {quoteValidateModal.open
          ? (
            <ModalComponent closeModal={() => setQuoteValidateModal({
              open: false,
              quote: null,
            })}
            >
              <div>
                <h3 className="text-align-center" style={{ width: 300, maxWidth: '100%' }}>
                  {strings.confirmationMessages.validateQuote.title}
                </h3>
                <p style={{ width: 300, maxWidth: '100%' }}>
                  {strings.confirmationMessages.validateQuote.message}
                </p>
                <div className="d-flex f-row justify-between align-center mt-4">
                  <button
                    type="button"
                    onClick={() => setQuoteValidateModal({
                      open: false,
                      quote: null,
                    })}
                  >
                    <span>{strings.cancel}</span>
                  </button>
                  <button
                    type="button"
                    onClick={() => initializeQuotationSignature.mutate(quoteValidateModal.quote.id)}
                  >
                    <span>
                      {!quoteValidateModal.quote.isAccepted
                        && !quoteValidateModal.quote.isAcceptedLocally
                        && quoteValidateModal.quote.hasFile
                        ? (!initializeQuotationSignature.isLoading
                          ? strings.digitalSignature
                          : strings.states.loading
                        ) : strings.confirm}
                    </span>
                  </button>
                </div>
              </div>
            </ModalComponent>
          ) : null}
        {quoteRefuseModal.open
          ? (
            <ModalComponent closeModal={() => setQuoteRefuseModal({
              open: false,
              quote: null,
            })}
            >
              <div>
                <h3 className="text-align-center" style={{ width: 300, maxWidth: '100%' }}>
                  {strings.confirmationMessages.refuseQuote.title}
                </h3>
                <p style={{ width: 300, maxWidth: '100%' }}>
                  {strings.confirmationMessages.refuseQuote.message}
                </p>
                <div className="d-flex f-row justify-between align-center mt-4">
                  <button
                    type="button"
                    onClick={() => setQuoteRefuseModal({
                      open: false,
                      quote: null,
                    })}
                  >
                    <span>{strings.cancel}</span>
                  </button>
                  <button
                    type="button"
                    onClick={() => refuseQuotation.mutate(quoteRefuseModal.quote)}
                  >
                    <span>
                      {isQuoteUnsigned(quoteRefuseModal)
                        ? (!initializeQuotationSignature.isLoading
                          ? strings.refuseSignature
                          : strings.states.loading
                        ) : strings.confirm}
                    </span>
                  </button>
                </div>
              </div>
            </ModalComponent>
          ) : null}
      </div>
      <div className="mt-3 d-flex pv-3 ph-5 white-background justify-between radius-5 f-wrap">
        <h5 className="medium-weight m-0 mb-3">{strings.quoteHelp}</h5>
        <ButtonComponent onClick={handleContactOpen}>
          {strings.contactUsSecondary}
        </ButtonComponent>
      </div>
      <ContactModal
        isOpen={contactModalOpen}
        onClose={() => setContactModalOpen(false)}
        source="MES DEVIS EXTRANET"
        defaultSubject={3}
      />
    </>
  );
};

QuoteTab.propTypes = {
  agencyContractId: PropTypes.string.isRequired,
  signed: PropTypes.bool.isRequired,
};

export default QuoteTab;
