import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from 'react-query';
import { LoaderComponent } from '@zolteam/axenergie-ui-library';

// Components

// Hooks
import { useAppContext } from '../../store/AppContext';

// Service
import AgencyContractService from '../../services/AgencyContractService';

// Constants
import strings from '../../constants/strings';
import utils from '../../utils/utils';
import colors from '../../constants/colors';
import InvoiceElement from './InvoiceElement';

const InvoiceTab = ({ agencyContractId }) => {
  const [, appDispatch] = useAppContext();

  const [invoiceList, setInvoiceList] = useState(null);

  const getMyAgencyContractInvoices = useQuery(['get-my-agency-contracts', agencyContractId], () => {
    if (agencyContractId) {
      return AgencyContractService.getMyAgencyContractInvoices({ agencyContractId });
    }
    return null;
  });

  const getAgencyContractInvoiceFile = useMutation('get-agency-contract-invoices',
    (fileId) => AgencyContractService.getAgencyContractInvoiceFile(fileId));

  useEffect(() => {
    if (getMyAgencyContractInvoices.isSuccess && getMyAgencyContractInvoices.data) {
      const invoices = getMyAgencyContractInvoices?.data?.data?.agencyContractInvoices;
      const categorizedInvoices = invoices?.reduce((acc, invoice) => {
        if (!invoice.hasFile) {
          return acc;
        }
        if (invoice.isPaidLocally === true || invoice.leftToPayIncludingTax === 0) {
          acc.payed.push(invoice);
          return acc;
        }
        if (invoice.leftToPayIncludingTax > 0) {
          acc.toBePayed.push(invoice);
          return acc;
        }
        acc.unknown.push(invoice);
        return acc;
      },
      { payed: [], toBePayed: [], unknown: [] });
      setInvoiceList(categorizedInvoices);
    }
    if (getMyAgencyContractInvoices.isError) {
      setInvoiceList({
        payed: [],
        toBePayed: [],
      });
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.getInvoices, isSuccess: false },
      });
    }
  }, [getMyAgencyContractInvoices.isSuccess, getMyAgencyContractInvoices.isError, getMyAgencyContractInvoices.data]);

  useEffect(() => {
    if (getAgencyContractInvoiceFile.isSuccess) {
      const file = getAgencyContractInvoiceFile?.data;

      if (file) {
        utils.downloadBlob(file?.data, file?.headers['x-file-sanitized-name']);
      }
    }
    if (getAgencyContractInvoiceFile.isError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.agencyDocumentNotFound, isSuccess: false },
      });
    }
  }, [getAgencyContractInvoiceFile.isSuccess, getAgencyContractInvoiceFile.isError]);

  const handleDocumentClick = (id) => {
    getAgencyContractInvoiceFile.mutate(id);
  };

  return (
    <>
      <div className="d-flex f-column white-background radius-5 pv-3 ph-5">
        {invoiceList === null && <LoaderComponent size={20} color={colors.grey800} borderWidth={3} />}
        <>
          <div className="mt-4">
            <div className="uppercase grey-400-text">
              {strings.pendingPayment}
            </div>
            {invoiceList?.toBePayed?.length > 0 && (
            <ul className="p-0 mt-0" style={{ listStyle: 'none', flexGrow: 1 }}>
              { invoiceList?.toBePayed?.map((invoice) => (
                <InvoiceElement
                  key={invoice.id}
                  handleDocumentClick={() => handleDocumentClick(invoice.id)}
                  invoice={invoice}
                />
              )) }
            </ul>
            )}
            {invoiceList?.toBePayed?.length !== undefined && invoiceList?.toBePayed?.length === 0
              ? <p>{strings.noInvoiceAvailable}</p>
              : null}
          </div>
          <div>
            <div className="uppercase grey-400-text">
              {strings.payed}
            </div>
            {invoiceList?.payed?.length > 0 && (
            <ul className="p-0 mt-0" style={{ listStyle: 'none', flexGrow: 1 }}>
              { invoiceList?.payed?.map((invoice) => (
                <InvoiceElement
                  key={invoice.id}
                  handleDocumentClick={() => handleDocumentClick(invoice.id)}
                  invoice={invoice}
                />
              )) }
            </ul>
            )}
            {invoiceList?.payed?.length !== undefined && invoiceList?.payed?.length === 0
              ? <p>{strings.noInvoiceAvailable}</p>
              : null}
          </div>
          <div>
            <div className="uppercase grey-400-text">
              {strings.invoiceUnknown}
            </div>
            <p className="small-text grey-400-text m-0">{strings.contactAgencyIfNeeded}</p>
            {invoiceList?.unknown?.length > 0 && (
            <ul className="p-0 mt-0" style={{ listStyle: 'none', flexGrow: 1 }}>
              { invoiceList?.unknown?.map((invoice) => (
                <InvoiceElement
                  key={invoice.id}
                  handleDocumentClick={() => handleDocumentClick(invoice.id)}
                  invoice={invoice}
                />
              )) }
            </ul>
            )}
            {invoiceList?.unknown?.length !== undefined && invoiceList?.unknown?.length === 0
              ? <p>{strings.noInvoiceAvailable}</p>
              : null}
          </div>
        </>
      </div>
    </>
  );
};

InvoiceTab.propTypes = {
  agencyContractId: PropTypes.string.isRequired,
};

export default InvoiceTab;
