import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  ButtonComponent, CheckboxComponent, InputComponent, LoaderComponent, SelectComponent,
} from '@zolteam/axenergie-ui-library';
import { useQuery } from 'react-query';

// Constants
import strings from '../../constants/strings';

// Utils
import { validateEmailField, validateNotEmptyField } from '../../utils/validators';

// Services and hooks
import UserService from '../../services/UserService';
import { useAppContext } from '../../store/AppContext';
import AgencyService from '../../services/AgencyService';

// Components
import FileInputWithAcceptAndSize from '../atoms/FileInputWithAcceptAndSize';
import colors from '../../constants/colors';
import { ROLES } from '../../constants/adherents';

const UserFormComponent = ({
  firstname,
  setFirstname,
  name,
  setName,
  job,
  setJob,
  mail,
  setMail,
  phone,
  setPhone,
  profile,
  profileId,
  setProfile,
  hasAvatar,
  avatar,
  avatarId,
  setAvatar,
  submit,
  adminType,
  adminId,
  agencies,
  setAgencies,
  isLoading,
  isHidden,
  setIsHidden,
  doNotShowOnMap,
  setdoNotShowOnMap,
  setCooperativeRoles,
  cooperativeRoles,
}) => {
  const [firstnameError, setFirstnameError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [jobError, setJobError] = useState(false);
  const [mailError, setMailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [profiles, setProfiles] = useState([]);

  const {
    isLoading: isRoleLoading, isError: isRoleError, isSuccess: isRoleSuccess, data: roles,
  } = useQuery('getRoles', UserService.getRoles);

  const {
    isSuccess: getAgenciesSuccess,
    isError: getAgenciesError,
    // isLoading: getAgenciesLoading,
    data: allAgencies,
  } = useQuery('getMyAgencies', () => {
    if (adminId) {
      return AgencyService.getAgencies({ adminId, isActive: 'true' });
    }
    return null;
  });

  // Store
  const [, appDispatch] = useAppContext();

  useEffect(() => {
    if (isRoleSuccess && roles) {
      const formattedProfiles = roles.data.roles.reduce(
        (acc, role) => {
          if (adminType && role.adminTypeId === adminType) {
            return [...acc, { value: role.id, label: role.name }];
          }
          if (!adminType) {
            return [...acc, { value: role.id, label: role.name }];
          }
          return acc;
        }, [],
      );
      setProfiles(formattedProfiles);
      if ((profile && !Object.keys(profile).length)) {
        setProfile(profileId ? formattedProfiles.find(({ value }) => value === profileId) : formattedProfiles[0]);
      }
    }
    if (isRoleError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.roleLoading, isSuccess: false },
      });
    }
  }, [isRoleError, isRoleSuccess, roles]);

  useEffect(() => {
    if (getAgenciesError) {
      appDispatch({
        type: 'SET_POPOVER',
        payload: { isOpen: true, title: strings.errors.agencyLoading, isSuccess: false },
      });
    }
  }, [getAgenciesError, getAgenciesSuccess, allAgencies]);

  useEffect(() => {
    if (profiles) {
      setProfile(profileId ? profiles.find(({ value }) => value === profileId) : profiles[0]);
    }
  }, [profileId, profiles]);

  const handleAgenciesChange = (agencyId) => {
    const newAgencies = agencies.includes(agencyId)
      ? agencies.filter((id) => id !== agencyId)
      : [...agencies, agencyId];
    setAgencies(newAgencies);
  };

  const onSubmit = () => {
    if (
      firstnameError
            || nameError
            || jobError
            || mailError
            || phoneError
    ) {
      return false;
    }
    return submit();
  };

  return (
    <div>
      <div className="mv-4 d-flex f-row f-wrap full-width align-center">
        <div className="form-input-width m-3">
          <InputComponent
            onChange={setFirstname}
            id="user-firstname"
            value={firstname}
            label={strings.firstname}
            isError={firstnameError}
            errorMessage={strings.errors.pleaseFillField}
            onBlur={() => validateNotEmptyField(setFirstnameError, firstname)}
          />
        </div>
        <div className="form-input-width m-3">
          <InputComponent
            onChange={setName}
            id="user-name"
            value={name}
            label={strings.name}
            isError={nameError}
            errorMessage={strings.errors.pleaseFillField}
            onBlur={() => validateNotEmptyField(setNameError, name)}
          />
        </div>
        <div className="form-input-width m-3">
          <InputComponent
            onChange={setJob}
            id="user-job"
            value={job}
            label={strings.job}
            isError={jobError}
            errorMessage={strings.errors.pleaseFillField}
            onBlur={() => validateNotEmptyField(setJobError, job)}
          />
        </div>
        <div className="form-input-width m-3">
          <InputComponent
            onChange={setMail}
            id="user-mail"
            value={mail}
            label={strings.mail}
            isError={mailError}
            errorMessage={strings.errors.mail}
            onBlur={() => validateEmailField(setMailError, mail)}
          />
        </div>
        <div className="form-input-width m-3">
          <InputComponent
            onChange={setPhone}
            id="user-phone"
            value={phone}
            label={strings.phone}
            isError={phoneError}
            errorMessage={strings.errors.pleaseFillField}
            onBlur={() => validateNotEmptyField(setPhoneError, phone)}
          />
        </div>
        <div className="form-input-width m-3">
          <SelectComponent
            data={profiles}
            name="user-profile"
            value={profile}
            setValue={setProfile}
            theme="dark"
            isLoading={isRoleLoading}
          />
        </div>
        {adminId ? null : (
          <>
            <div className="m-3 d-flex f-row f-wrap">
              <div className="flex-1 mr-3">
                {ROLES.slice(0, 4).map(({ code, id }) => (
                  <CheckboxComponent
                    handleChange={() => {
                      setCooperativeRoles((state) => {
                        if (state.filter((item) => item === id)?.length === 0) {
                          return state.concat([id]);
                        }
                        return state.filter((item) => item !== id);
                      });
                    }}
                    label={code}
                    id={id}
                    value={cooperativeRoles?.filter((item) => item === id)?.length > 0}
                  />
                ))}
              </div>
              <div className="flex-1">
                {ROLES.slice(4, 8).map(({ code, id }) => (
                  <CheckboxComponent
                    handleChange={() => {
                      setCooperativeRoles((state) => {
                        if (state.filter((item) => item === id)?.length === 0) {
                          return state.concat([id]);
                        }
                        return state.filter((item) => item !== id);
                      });
                    }}
                    label={code}
                    id={id}
                    value={cooperativeRoles?.filter((item) => item === id)?.length > 0}
                  />
                ))}
              </div>
            </div>
            <div className="m-3">
              <CheckboxComponent
                label={strings.hideFromAdherentBook}
                handleChange={() => setIsHidden((state) => !state)}
                id="is-hidden"
                value={isHidden}
              />
              <CheckboxComponent
                label={strings.hideFromAdherentMap}
                handleChange={() => setdoNotShowOnMap((state) => !state)}
                id="is-hidden-map"
                value={doNotShowOnMap}
              />
            </div>
          </>
        )}
        {hasAvatar
          ? (
            <div className="form-file-input-width m-3">
              <FileInputWithAcceptAndSize
                setFile={setAvatar}
                file={avatar}
                canDelete={avatar || avatarId}
                label={avatarId ? strings.changeAvatar : strings.chooseAvatar}
                fileType={adminType === 2 ? 1 : 15}
                initName={strings.changeAvatar}
              />
            </div>
          )
          : null}
        {adminId
          ? (
            <div className="d-flex f-column align-start m-3">
              <h5 className="mt-0 mb-2">{strings.agencies}</h5>
              {
                allAgencies?.data?.agencies
                  ? allAgencies?.data?.agencies?.map((agency) => (
                    <CheckboxComponent
                      label={agency?.name}
                      handleChange={() => handleAgenciesChange(agency.id)}
                      id={agency.name}
                      key={agency.name}
                      value={agencies?.includes(agency?.id) || false}
                    />
                  ))
                  : null
              }
            </div>
          )
          : null}
      </div>

      { !firstname
            || !name
            || !job
            || !mail
            || !phone
        ? null : (
          <div className="m-3">
            <ButtonComponent onClick={onSubmit}>
              <div className="mv-2">
                {
                  isLoading
                    ? <LoaderComponent size={30} borderWidth={5} color={colors.white} />
                    : <span className="uppercase m-5">{strings.save}</span>
                }
              </div>
            </ButtonComponent>
          </div>
        )}
    </div>
  );
};

UserFormComponent.propTypes = {
  firstname: PropTypes.string.isRequired,
  setFirstname: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired,
  job: PropTypes.string.isRequired,
  setJob: PropTypes.func.isRequired,
  mail: PropTypes.string.isRequired,
  setMail: PropTypes.func.isRequired,
  phone: PropTypes.string.isRequired,
  setPhone: PropTypes.func.isRequired,
  profileId: PropTypes.number,
  profile: PropTypes.shape({ value: PropTypes.number, label: PropTypes.string }),
  setProfile: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  hasAvatar: PropTypes.bool,
  avatar: PropTypes.shape({}) || null,
  avatarId: PropTypes.number,
  setAvatar: PropTypes.func,
  adminType: PropTypes.number,
  adminId: PropTypes.number,
  agencies: PropTypes.arrayOf(PropTypes.number),
  setAgencies: PropTypes.func,
  isLoading: PropTypes.bool,
  isHidden: PropTypes.bool,
  setIsHidden: PropTypes.func,
  doNotShowOnMap: PropTypes.bool,
  setdoNotShowOnMap: PropTypes.func,
  cooperativeRoles: PropTypes.arrayOf(PropTypes.number),
  setCooperativeRoles: PropTypes.func,
};

UserFormComponent.defaultProps = {
  avatar: null,
  avatarId: null,
  setAvatar: () => {},
  hasAvatar: false,
  profileId: null,
  adminType: null,
  adminId: null,
  agencies: [],
  setAgencies: () => {},
  profile: null,
  isLoading: false,
  isHidden: null,
  setIsHidden: () => {},
  doNotShowOnMap: null,
  setdoNotShowOnMap: () => {},
  cooperativeRoles: [],
  setCooperativeRoles: () => {},
};

export default UserFormComponent;
